import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "../..";

const Empty: React.FC<{ overlayHide(): void }> = ({ overlayHide }) => (
  <div className="cart__empty">
    <h4><FormattedMessage id="your-bag-is-empty"></FormattedMessage></h4>
    <p>
      <FormattedMessage id="your-bag-is-empty-message"></FormattedMessage>
    </p>
    <div className="cart__empty__action">
      <Button secondary onClick={overlayHide}>
        <FormattedMessage id="continue-shopping"></FormattedMessage>
      </Button>
    </div>
  </div>
);

export default Empty;
