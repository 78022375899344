import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import "./scss/index.scss";


const FooterBottom: FC = () => (
  <footer className="footer-bottom">
    <div className="container">
      <p>{new Date().getFullYear()} &copy; <FormattedMessage id="footer-text"/></p>
    </div>
  </footer>
);

export default FooterBottom;
