import React from "react";
import { FormattedMessage } from "react-intl";

import useLocale from "@temp/hooks/useLocale";
import { CachedImage } from "../CachedImage";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Row with product to display in cart summary.
 */
const CartSummaryRow: React.FC<IProps> = ({
  attributes,
  index,
  sku,
  thumbnail,
}: IProps) => {
  const { locale } = useLocale();

  return (
    <S.Wrapper>
      <div data-cy={`cartSummaryItem${index}Name`}>
        {attributes.map(({ attribute, values }) => (
            <S.Name>
              {locale !== 'en' && attribute.translation ? attribute.translation.name : attribute.name}
              : {values.map(value => value.name).join(", ")}
            </S.Name>
          ))
        }
      </div>
      <S.Sku>
        <FormattedMessage id="sku"></FormattedMessage>: <span data-cy={`cartSummaryItem${index}SKU`}>{sku}</span>
      </S.Sku>
      <S.Photo>
        <CachedImage data-cy={`cartSummaryItem${index}Image`} {...thumbnail} />
      </S.Photo>
    </S.Wrapper>
  );
};

export { CartSummaryRow };
