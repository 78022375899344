import "./scss/index.scss";

import * as React from "react";

import { LanguageCode } from "@sdk/types/globalTypes";
import { injectTranslation } from "@temp/core/utils";
import useLocale from "@temp/hooks/useLocale";

import { MetaWrapper } from "../../components";
import Page from "./Page";
import { TypedHomePageQuery } from "./queries";


const View: React.FC = () => {
  const { locale } = useLocale();

  return (
    <div className="home-page">
      <TypedHomePageQuery
        alwaysRender
        displayLoader={false}
        errorPolicy="all"
        variables={{ locale: LanguageCode[locale] || LanguageCode.en }}
      >
        {({ data, loading }) => {
          return (
            <>
              {injectTranslation((getTranslation) => (
                <MetaWrapper
                  meta={{
                    description: data.shop ? data.shop.description : "",
                    title: getTranslation({ id: "site-title" }),
                  }}
                >
                  <Page
                    loading={loading}
                    backgroundImage={
                      data.shop &&
                      data.shop.homepageCollection &&
                      data.shop.homepageCollection.backgroundImage
                    }
                    categories={data.categories}
                    shop={data.shop}
                  />
                </MetaWrapper>
              ))}
            </>
          );
        }}
      </TypedHomePageQuery>
    </div>
  );
};

export default View;
