import { media, styled } from "@styles";

export const Wrapper = styled.div<{
  showShipping: boolean;
  showDiscount: boolean;
}>`
  display: grid;
  font-size: ${props => props.theme.typography.h4FontSize};
  grid-template-areas:
    ". subtotalText subtotalPrice ."
    ${props => props.showShipping && `". shippingText shippingPrice ."`}
    ${props => props.showDiscount && `". discountText discountPrice ."`}
    ". totalText totalPrice .";
  grid-template-columns: 3.5fr 0.75fr 0.75fr 0.0fr;
  grid-gap: 2rem 0;
  padding: 2rem 0;
  ${props => media.mediumScreen`
    grid-template-areas:
      ". subtotalText subtotalPrice"
      ${props.showShipping && `". shippingText shippingPrice"`}
      ${props.showDiscount && `". discountText discountPrice"`}
      ". totalText totalPrice";
    grid-template-columns: 0.0fr 3.5fr 2fr;
  `}
  ${props => media.smallScreen`
  grid-template-areas:
    "subtotalText subtotalPrice"
    ${props.showShipping && `"shippingText shippingPrice"`}
    ${props.showDiscount && `"discountText discountPrice"`}
    "totalText totalPrice";
  grid-template-columns: 2fr 2fr;
`}
  border-bottom: 1px solid rgba(50, 50, 50, 0.1);
`;

export const CartFooterColumn = styled.div`
  padding: 0 0.5rem;
`

export const SubtotalText = styled(CartFooterColumn)`
  grid-area: subtotalText;
`;

export const SubtotalPrice = styled(CartFooterColumn)`
  grid-area: subtotalPrice;
  ${media.mediumScreen`
    text-align: right;
  `}
`;

export const ShippingText = styled(CartFooterColumn)`
  grid-area: shippingText;
`;

export const ShippingPrice = styled(CartFooterColumn)`
  grid-area: shippingPrice;
  ${media.mediumScreen`
    text-align: right;
  `}
`;

export const DiscountText = styled(CartFooterColumn)`
  grid-area: discountText;
`;

export const DiscountPrice = styled(CartFooterColumn)`
  grid-area: discountPrice;
  ${media.mediumScreen`
    text-align: right;
  `}
`;

export const TotalText = styled(CartFooterColumn)`
  grid-area: totalText;
  font-weight: bold;
`;

export const TotalPrice = styled(CartFooterColumn)`
  grid-area: totalPrice;
  font-weight: bold;
  text-align: center;
  ${media.mediumScreen`
    text-align: right;
  `}
`;
