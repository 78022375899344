import React from "react";
import { FormattedMessage } from "react-intl";

import { Link } from "react-router-dom";
import { Button } from "..";
import { OverlayContextInterface } from "../Overlay";

const CheckoutAsGuest: React.FC<{
  overlay: OverlayContextInterface;
  checkoutUrl: string;
}> = ({ overlay, checkoutUrl }) => (
  <div className="checkout-login__guest">
    <h3 className="checkout__header"><FormattedMessage id="continue-as-a-guest"></FormattedMessage></h3>
    <p>
    <FormattedMessage id="guest-message"></FormattedMessage>
    </p>
    <Link to={checkoutUrl}>
      <Button><FormattedMessage id="continue-as-a-guest"></FormattedMessage></Button>
    </Link>

    {/* <p>
      or you can{" "}
      <span
        className="u-link"
        onClick={() => overlay.show(OverlayType.register, OverlayTheme.right)}
      >
        create an account
      </span>
    </p> */}
  </div>
);

export default CheckoutAsGuest;
