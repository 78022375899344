import React from "react";
import { FormattedMessage } from "react-intl";

import * as S from "./styles";

/**
 * Cart header to use with conjunction of cart rows.
 */
const CartHeader: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Column><FormattedMessage id="products"></FormattedMessage></S.Column>
      <S.Column><FormattedMessage id="variant"></FormattedMessage></S.Column>
      <S.Column><FormattedMessage id="total-price"></FormattedMessage></S.Column>
      <S.Column/>
    </S.Wrapper>
  );
};

export { CartHeader };
