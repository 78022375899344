import gql from "graphql-tag";

export const categoryQuery = gql`
  query CategoryDetails($id: ID!, $locale: LanguageCodeEnum!) {
    category(id: $id) {
      seoDescription
      seoTitle
      id
      name
      backgroundImage {
        url
      }
      translation: translation(languageCode: $locale) {
        name
      }
      ancestors(last: 5) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
