import { smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Media from "react-media";

import CostRow from "./CostRow";
import ProductRow, { EditableProductRowProps, ILine } from "./ProductRow";

interface TableProps extends EditableProductRowProps {
  lines: ILine[];
  subtotal: React.ReactNode;
  deliveryCost?: React.ReactNode;
  totalCost?: React.ReactNode;
  discount?: React.ReactNode;
  discountName?: string;
}

const Table: React.FC<TableProps> = ({
  subtotal,
  deliveryCost,
  totalCost,
  discount,
  discountName,
  lines,
  ...rowProps
}) => {
  const intl = useIntl();

  return (
    <Media query={{ minWidth: smallScreen }}>
      {mediumScreen => (
        <table className="cart-table">
          <thead>
            <tr>
              <th><FormattedMessage id="products"></FormattedMessage></th>
              {mediumScreen && <th><FormattedMessage id="price"></FormattedMessage></th>}
              <th><FormattedMessage id="variant"></FormattedMessage></th>
              <th className="cart-table__quantity-header"><FormattedMessage id="quantity"></FormattedMessage></th>
              <th colSpan={2}>
                {mediumScreen ?
                  <FormattedMessage id="total-price"></FormattedMessage> :
                  <FormattedMessage id="price"></FormattedMessage>
                }
              </th>
            </tr>
          </thead>
          <tbody>
            {lines.map(line => (
              <ProductRow
                key={line.id}
                line={line}
                mediumScreen={mediumScreen}
                {...rowProps}
              />
            ))}
          </tbody>
          <tfoot>
            {discount && (
              <CostRow
                mediumScreen={mediumScreen}
                heading={`${intl.formatMessage({id: "discount"})}: ${discountName}`}
                cost={discount}
              />
            )}
            {totalCost && (
              <CostRow
                mediumScreen={mediumScreen}
                heading={intl.formatMessage({id: "total-cost"})}
                cost={totalCost}
              />
            )}
          </tfoot>
        </table>
      )}
    </Media>
  );
};

export default Table;
