import { styled } from "@styles";

export const TermsMessages = styled.div`
  text-align: justify;
`;

export const HighlightedTerm = styled.div`
  color: #c22d74;
  font-weight: bold;
`;
