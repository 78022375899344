import React from "react";
import { FormattedMessage } from "react-intl";

import * as S from "./styles";
import { IProps } from "./types";

export const ErrorMessage: React.FC<IProps> = ({ errors }: IProps) =>
  errors && errors.length ? (
    <S.ErrorMessage>
      {errors.map((error, index) => (
        <S.ErrorParagraph key={index}>
          {error.code! ? (
            <FormattedMessage
              id={error.code}
              defaultMessage={error.message}
            ></FormattedMessage>
          ) : (
            error.message
          )}
        </S.ErrorParagraph>
      ))}
    </S.ErrorMessage>
  ) : null;
