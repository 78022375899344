import React from "react";
import { components } from "react-select";
import { ControlProps } from "react-select/lib/components/Control";
import { InputProps } from "react-select/lib/components/Input";
import { OptionProps } from "react-select/lib/components/Option";
import { ThemeContext } from "styled-components";

import { Icon, Select } from "@components/atoms";
import useLocale from "@temp/hooks/useLocale";

import { turquoise } from "@styles/constants";
import * as S from "./styles";

const LocaleSelect: React.FC = () => {
  const { locale, setLocale } = useLocale();

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      ":hover": {
        border: "none",
      },
      background: "none",
      border: "none",
      boxShadow: 0,
      margin: 0,
      padding: 0,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      ":hover": {
        color: turquoise,
      },
      fontWeight: 600,
    }),
    valueContainer: (provided: any) => {
      return {
        ...provided,
        margin: 0,
      };
    },
  };

  const customComponents = {
    Control: (props: ControlProps<any>) => {
      const customTheme = React.useContext(ThemeContext);
      return (
        <>
          <components.Control
            data-cy="input-select"
            {...{ customTheme, ...props }}
          />
        </>
      );
    },
    IndicatorSeparator: () => null,
    IndicatorsContainer: ({ selectProps, hasValue, clearValue }: any) => {
      const showClearIndicator =
        selectProps.isClearable ||
        (selectProps.isMulti && selectProps.isClearable === undefined);

      if (showClearIndicator && hasValue) {
        return (
          <S.ClearIndicator onClick={clearValue}>
            <Icon name="select_x" size={10} />
          </S.ClearIndicator>
        );
      } else {
        // Boolean to string conversion done due to
        // https://github.com/styled-components/styled-components/issues/1198
        return (
          <S.DropdownIndicator rotate={String(selectProps.menuIsOpen)}>
            <Icon name="select_arrow" size={10} />
          </S.DropdownIndicator>
        );
      }
    },
    Input: (props: InputProps) => {
      return <components.Input {...{ ...props }} />;
    },
    Option: (props: OptionProps<any>) => {
      const customTheme = React.useContext(ThemeContext);
      return <components.Option {...{ customTheme, ...props }} />;
    },
  };

  const options = [
    {
      label: "EN",
      value: "en",
    },
    {
      label: "PL",
      value: "pl",
    },
  ];

  return (
    <S.Wrapper>
      <Select
        options={options}
        isSearchable={false}
        defaultValue={options.find(option => option.value === locale)}
        customStyles={customStyles}
        customComponents={customComponents}
        onChange={option => {
          setLocale(option.value);
        }}
      />
    </S.Wrapper>
  );
};

export { LocaleSelect };
