import { styled } from "@styles";

export const Wrapper = styled.div`
  min-width: 80px;
  height: 100%;

  div:first-child {
    margin-bottom: 0;
  }
`;

export const DropdownIndicator = styled.div<{ rotate: string }>`
  position: absolute;
  right: 1rem;
  transition-duration: 0.3s;
  transform: ${props => props.rotate === "true" ? "rotate(180deg)" : "rotate(0deg)"};
`;

export const ClearIndicator = styled.div`
  position: absolute;
  right: 1rem;
`;

