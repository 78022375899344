import React from "react";
import { FormattedMessage } from "react-intl";

import { IconButton } from "@components/atoms";
import { CachedImage } from "@components/molecules";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Product row displayed on cart page
 */
export const CartRow: React.FC<IProps> = ({
  index,
  totalPrice,
  name,
  sku,
  thumbnail,
  attributes = [],
  onRemove,
}: IProps) => {
  return (
    <S.Wrapper>
      <S.Photo>
        <CachedImage data-cy={`cartPageItem${index}Image`} {...thumbnail} />
      </S.Photo>

      <S.Description>
        <S.Name data-cy={`cartPageItem${index}Name`}>{name}</S.Name>
        <S.Sku>
          <S.LightFont>
            <FormattedMessage id="sku"></FormattedMessage>:{" "}
            <span data-cy={`cartPageItem${index}SKU`}>{sku ? sku : "-"}</span>
          </S.LightFont>
        </S.Sku>
      </S.Description>

      <S.Attributes id="attributes" data-cy={`cartPageItem${index}Attributes`}>
          {attributes.map(({ attribute, values }, attributeIndex) => (
            <S.SingleAttribute key={attribute.id}>
              <span
                data-cy={`cartPageItem${index}SingleAttribute${attributeIndex}`}
              >
                <S.SmallFont>{attribute.name}:</S.SmallFont>{" "}
                {values.map(value => value.name).join(", ")}
              </span>
            </S.SingleAttribute>
          ))}
      </S.Attributes>

      <S.Trash>
        <IconButton
          data-cy={`cartPageItem${index}BtnRemove`}
          size={22}
          name="trash"
          onClick={onRemove}
        />
      </S.Trash>

      <S.TotalPrice>
        <S.PriceLabel>
          <S.LightFont><FormattedMessage id="total-price"></FormattedMessage>:</S.LightFont>
        </S.PriceLabel>
        <p>{totalPrice}</p>
      </S.TotalPrice>
    </S.Wrapper>
  );
};
