import React, {
  forwardRef,
  RefForwardingComponent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { RouteComponentProps, useHistory } from "react-router";

import { CheckoutPayment } from "@components/organisms";
import { useCart, useCheckout, useUserDetails } from "@sdk/react";
import { CHECKOUT_STEPS } from "@temp/core/config";
import useLocale from "@temp/hooks/useLocale";
import { IAddress, ICardData, IFormError } from "@types";
import { countriesEn, countriesPl } from "types/countries";

export interface ICheckoutPaymentSubpageHandles {
  submitPayment: () => void;
}
interface IProps extends RouteComponentProps<any> {
  selectedPaymentGateway?: string;
  selectedPaymentGatewayToken?: string;
  selectPaymentGateway: (paymentGateway: string) => void;
  changeSubmitProgress: (submitInProgress: boolean) => void;
}

const CheckoutPaymentSubpageWithRef: RefForwardingComponent<
  ICheckoutPaymentSubpageHandles,
  IProps
> = (
  {
    selectedPaymentGateway,
    selectedPaymentGatewayToken,
    changeSubmitProgress,
    selectPaymentGateway,
    ...props
  }: IProps,
  ref
) => {
    const history = useHistory();
    const { data: user } = useUserDetails();
    const {
      checkout,
      billingAsShipping,
      setBillingAddress,
      setBillingAsShippingAddress,
      selectedBillingAddressId,
      availablePaymentGateways,
      promoCodeDiscount,
      addPromoCode,
      removePromoCode,
      createPayment,
    } = useCheckout();
    const { items } = useCart();
    const { locale } = useLocale();
    const countries = locale !== "en" ? countriesPl : countriesEn;
    const intl = useIntl();

    const isShippingRequiredForProducts =
      items &&
      items.some(
        ({ variant }) => variant.product?.productType.isShippingRequired
      );

    const [billingErrors, setBillingErrors] = useState<IFormError[]>([]);
    const [gatewayErrors, setGatewayErrors] = useState<IFormError[]>([]);
    const [promoCodeErrors, setPromoCodeErrors] = useState<IFormError[]>([]);
    const [termsErrors, setTermsErrors] = useState<IFormError[]>([]);
    const [emailTermsErrors, setEmailTermsErrors] = useState<IFormError[]>([]);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptEmailTerms, setAcceptEmailTerms] = useState(false);

    const [billingAsShippingState, setBillingAsShippingState] = useState(
      billingAsShipping
    );
    useEffect(() => {
      setBillingAsShippingState(billingAsShipping);
    }, [billingAsShipping]);

    const checkoutBillingAddress = checkout?.billingAddress
      ? {
        ...checkout?.billingAddress,
        phone: checkout?.billingAddress?.phone || undefined,
      }
      : undefined;
    const paymentGateways = availablePaymentGateways
      ? availablePaymentGateways
      : [];

    const checkoutBillingFormId = "billing-form";
    const checkoutBillingFormRef = useRef<HTMLFormElement>(null);
    const checkoutGatewayFormId = "gateway-form";
    const checkoutGatewayFormRef = useRef<HTMLFormElement>(null);
    const checkoutNewAddressFormId = "new-address-form";
    const promoCodeDiscountFormId = "discount-form";
    const promoCodeDiscountFormRef = useRef<HTMLFormElement>(null);

    useImperativeHandle(ref, () => ({
      submitPayment: async () => {
        if (billingAsShippingState) {
          handleSetBillingAddress();
        } else if (user && selectedBillingAddressId) {
          checkoutBillingFormRef.current?.dispatchEvent(
            new Event("submit", { cancelable: true })
          );
        } else {
          const isValid = await handleTerms();
          if (!isValid) {
            return;
          }

          checkoutBillingFormRef.current?.dispatchEvent(
            new Event("submit", { cancelable: true })
          );
        }
      },
    }));

    const handleTerms = async () => {

      if (!acceptEmailTerms) {
        setEmailTermsErrors([{ message: intl.formatMessage({ id: "error-accept-terms" }) }]);
      } else {
        setEmailTermsErrors([]);
      }

      if (!acceptTerms) {
        setTermsErrors([{ message: intl.formatMessage({ id: "error-accept-terms" }) }]);
      } else {
        setTermsErrors([]);
      }


      return acceptEmailTerms  && acceptTerms;
    }

    const handleProcessPayment = async (
      gateway: string,
      token: string,
      cardData?: ICardData
    ) => {
      const { dataError } = await createPayment(gateway, token, cardData);
      const errors = dataError?.error;
      changeSubmitProgress(false);
      if (errors) {
        setGatewayErrors(errors);
      } else {
        setGatewayErrors([]);
        history.push(CHECKOUT_STEPS[2].nextStepLink);
      }
    };
    const handlePaymentGatewayError = () => {
      changeSubmitProgress(false);
    };
    const handleSetBillingAddress = async (
      address?: IAddress,
      email?: string,
      userAddressId?: string
    ) => {
      if (!address && !billingAsShippingState) {
        setBillingErrors([{ message: intl.formatMessage({ id: "error-billing-address" }) }]);

        return;
      }

      const billingEmail = user?.email || email;

      if (
        !billingEmail &&
        !billingAsShippingState &&
        !isShippingRequiredForProducts
      ) {
        setBillingErrors([
          { field: "email", message: intl.formatMessage({ id: "error-email-address" }) },
        ]);

        return;
      }

      const firstName = user?.firstName || address?.firstName;
      const dateOfBirth = address?.streetAddress2;

      if (!firstName) {
        setBillingErrors([
          { field: "firstName", message: intl.formatMessage({ id: "error-field-required" }) },
        ]);

        return;
      }

      if (!dateOfBirth) {
        setBillingErrors([
          { field: "streetAddress2", message: intl.formatMessage({ id: "error-field-required" }) },
        ]);

        return;
      }

      const lastName = user?.firstName || address?.lastName;

      if (!lastName) {
        setBillingErrors([
          { field: "lastName", message: intl.formatMessage({ id: "error-field-required" }) },
        ]);

        return;
      }

      let errors;
      changeSubmitProgress(true);
      if (billingAsShippingState && isShippingRequiredForProducts) {
        const { dataError } = await setBillingAsShippingAddress();
        errors = dataError?.error;
      } else {
        const { dataError } = await setBillingAddress(
          {
            ...address,
            id: userAddressId,
          },
          billingEmail
        );
        errors = dataError?.error;
      }
      if (errors) {
        changeSubmitProgress(false);
        setBillingErrors(errors);
      } else {
        setBillingErrors([]);
        if (promoCodeDiscountFormRef.current) {
          promoCodeDiscountFormRef.current?.dispatchEvent(
            new Event("submit", { cancelable: true })
          );
        } else if (checkoutGatewayFormRef.current) {
          checkoutGatewayFormRef.current.dispatchEvent(
            new Event("submit", { cancelable: true })
          );
        } else {
          changeSubmitProgress(false);
          setGatewayErrors([{ message: intl.formatMessage({ id: "error-choose-payment-method" }) }]);
        }
      }
    };
    const handleAddPromoCode = async (promoCode: string) => {
      const { dataError } = await addPromoCode(promoCode);
      const errors = dataError?.error;
      if (errors) {
        changeSubmitProgress(false);
        setPromoCodeErrors(errors);
      } else {
        setPromoCodeErrors([]);
        if (checkoutGatewayFormRef.current) {
          checkoutGatewayFormRef.current.dispatchEvent(
            new Event("submit", { cancelable: true })
          );
        } else {
          changeSubmitProgress(false);
          setGatewayErrors([{ message: intl.formatMessage({ id: "error-choose-payment-method" }) }]);
        }
      }
    };
    const handleRemovePromoCode = async (promoCode: string) => {
      const { dataError } = await removePromoCode(promoCode);
      const errors = dataError?.error;
      if (errors) {
        changeSubmitProgress(false);
        setPromoCodeErrors(errors);
      } else {
        setPromoCodeErrors([]);
        if (checkoutGatewayFormRef.current) {
          checkoutGatewayFormRef.current.dispatchEvent(
            new Event("submit", { cancelable: true })
          );
        } else {
          changeSubmitProgress(false);
          setGatewayErrors([{ message: intl.formatMessage({ id: "error-choose-payment-method" }) }]);
        }
      }
    };
    const handleSubmitUnchangedDiscount = () => {
      if (checkoutGatewayFormRef.current) {
        checkoutGatewayFormRef.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      } else {
        changeSubmitProgress(false);
        setGatewayErrors([{ message: intl.formatMessage({ id: "error-choose-payment-method" }) }]);
      }
    };

    return (
      <CheckoutPayment
        {...props}
        billingErrors={billingErrors}
        gatewayErrors={gatewayErrors}
        billingFormId={checkoutBillingFormId}
        billingFormRef={checkoutBillingFormRef}
        selectedUserAddressId={selectedBillingAddressId}
        checkoutBillingAddress={checkoutBillingAddress}
        countries={countries}
        paymentGateways={paymentGateways}
        selectedPaymentGateway={selectedPaymentGateway}
        selectedPaymentGatewayToken={selectedPaymentGatewayToken}
        selectPaymentGateway={selectPaymentGateway}
        setBillingAddress={handleSetBillingAddress}
        billingAsShippingPossible={!!isShippingRequiredForProducts}
        billingAsShippingAddress={billingAsShippingState}
        setBillingAsShippingAddress={setBillingAsShippingState}
        promoCodeDiscountFormId={promoCodeDiscountFormId}
        promoCodeDiscountFormRef={promoCodeDiscountFormRef}
        promoCodeDiscount={{
          voucherCode: promoCodeDiscount?.voucherCode,
        }}
        addPromoCode={handleAddPromoCode}
        removeVoucherCode={handleRemovePromoCode}
        submitUnchangedDiscount={handleSubmitUnchangedDiscount}
        promoCodeErrors={promoCodeErrors}
        gatewayFormId={checkoutGatewayFormId}
        gatewayFormRef={checkoutGatewayFormRef}
        userId={user?.id}
        newAddressFormId={checkoutNewAddressFormId}
        processPayment={handleProcessPayment}
        onGatewayError={handlePaymentGatewayError}
        termsErrors={termsErrors}
        emailTermsErrors={emailTermsErrors}
        acceptTerms={acceptTerms}
        acceptEmailTerms={acceptEmailTerms}
        setAcceptTerms={setAcceptTerms}
        setAcceptEmailTerms={setAcceptEmailTerms}
      />
    );
  };

const CheckoutPaymentSubpage = forwardRef(CheckoutPaymentSubpageWithRef);

export { CheckoutPaymentSubpage };
