import React from "react";
import { FormattedMessage } from "react-intl";

import { Checkbox, ErrorMessage } from "@components/atoms";

import * as S from "./styles";
import { IProps } from "./types";

const CheckoutTerms: React.FC<IProps> = ({
  emailTermsErrors,
  termsErrors,
  acceptEmailTerms,
  acceptTerms,
  setAcceptEmailTerms,
  setAcceptTerms,
}: IProps) => {

  const changeAcceptEmailTerms = () => {
    const terms = !acceptEmailTerms;
      if (setAcceptEmailTerms) {
          setAcceptEmailTerms(terms);
      }
  };

  const changeAcceptTerms = () => {
    const terms = !acceptTerms;
      if (setAcceptTerms) {
          setAcceptTerms(terms);
      }
  };

  return (
    <S.TermsMessages>

        <Checkbox
        data-cy="checkoutTermsCheckbox"
        name="terms_all"
        checked={acceptTerms}
        onChange={changeAcceptTerms}
        >
        <div>
            <FormattedMessage id="terms-message" />
        </div>
        </Checkbox>
        <ErrorMessage errors={termsErrors} />

        <Checkbox
        data-cy="checkoutEmailTermsCheckbox"
        name="terms"
        checked={acceptEmailTerms}
        onChange={changeAcceptEmailTerms}
        >
          <div>
            <FormattedMessage id="terms-email-message" />
            <S.HighlightedTerm >
              <FormattedMessage id="terms-email-message-highlight" />
          </S.HighlightedTerm>
        </div>
      </Checkbox>
      <ErrorMessage errors={emailTermsErrors} />
    </S.TermsMessages>
  );
};

export { CheckoutTerms };
