import React from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@components/atoms";
import { Container } from "@components/templates";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Thank you page after completing the checkout.
 */
const ThankYou: React.FC<IProps> = ({
  orderNumber,
  continueShopping,
  orderDetails,
}: IProps) => {
  return (
    <Container>
      <S.Wrapper>
        <S.ThankYouHeader>
          <FormattedMessage id="thank-you"></FormattedMessage>
          <br />
          <span><FormattedMessage id="for-your-order"></FormattedMessage></span>
        </S.ThankYouHeader>
        <S.Paragraph>
        <FormattedMessage id="your-order-number-is"></FormattedMessage> <span>{orderNumber}</span>
        </S.Paragraph>
        <S.Paragraph>
          <FormattedMessage id="thank-you-message"></FormattedMessage>
        </S.Paragraph>
        <S.Buttons>
          <Button onClick={continueShopping} color="secondary" fullWidth={true}>
            <FormattedMessage id="continue-shopping"></FormattedMessage>
          </Button>
          <Button onClick={orderDetails} fullWidth={true}>
            <FormattedMessage id="order-details"></FormattedMessage>
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </Container>
  );
};

export { ThankYou };
