import "./scss/index.scss";

import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Loader,ProductsFeatured } from "../../components";

import {
  ProductsList_categories,
  ProductsList_shop,
  ProductsList_shop_homepageCollection_backgroundImage,
} from "./types/ProductsList";

import { structuredData } from "../../core/SEO/Homepage/structuredData";

const Page: React.FC<{
  loading: boolean;
  categories: ProductsList_categories;
  backgroundImage: ProductsList_shop_homepageCollection_backgroundImage;
  shop: ProductsList_shop;
}> = ({ loading, categories, backgroundImage, shop }) => {
  const categoriesExist = () => {
    return categories && categories.edges && categories.edges.length > 0;
  };
  const intl = useIntl();

  return (
    <>
      <script className="structured-data-list" type="application/ld+json">
        {structuredData(shop)}
      </script>
      <div
        className="home-page__hero"
        style={
          backgroundImage
            ? { backgroundImage: `url(${backgroundImage.url})` }
            : null
        }
      >
        <div className="home-page__hero-text">
        <div>
            <span className="home-page__hero__title">
              <h1><FormattedMessage id="pilgrimages"/></h1>
            </span>
          </div>
          <div>
            <span className="home-page__hero__title">
              <h1><FormattedMessage id="registration-tickets"/></h1>
            </span>
          </div>
        </div>
        <div className="home-page__hero-action">
          {loading && !categories ? (
            <Loader />
          ) : (
            categoriesExist()
          )}
        </div>
      </div>
      <ProductsFeatured title={intl.formatMessage({id: "pilgrimages"})} />
    </>
  );
};

export default Page;
